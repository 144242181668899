<template>
  <b-card class="mt-0 mb-0">
    <b-overlay :show="show" rounded="sm">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="10">
            <b-row>
              <b-col md="2" class="m-0 p-0">
                <v-select
                    v-model="_search.stores"
                    label="store_name"
                    multiple
                    :options="_stores"
                    :reduce="store => store.id"
                    class="select-size-sm"
                    placeholder="Pazaryeri"
                    :closeOnSelect="false"
                />
              </b-col>

              <b-col md="2" class="m-0 p-0">
                <v-select
                    v-model="_search.statuses"
                    label="name"
                    multiple
                    :options="_statuses"
                    :reduce="store => store.key"
                    class="select-size-sm"
                    placeholder="Durum"
                    :closeOnSelect="false"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="2">
            <b-button-group size="sm">
              <b-button variant="primary" type="submit" @click="pageClick(1)" title="Getir">
                <feather-icon icon="FilterIcon"></feather-icon>
              </b-button>
              <b-button variant="danger" type="reset" title="Temizle">
                <feather-icon icon="XCircleIcon"></feather-icon>
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </b-card>
</template>
<script>
import {mapGetters} from "vuex";
import vSelect from 'vue-select'

export default {
  name: 'QuestionSearch',
  components: {
    vSelect,
  },
  data: () => ({
    show: false,
  }),
  computed: {
    ...mapGetters('question', ['_search', '_statuses']),
    ...mapGetters('store', ['_stores']),
  },
  methods: {
    pageClick(page) {
      this.show = true
      this._search.page = page
      this.$store.dispatch('question/getAll2')
          .finally(() => {
            this.show = false
          })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>