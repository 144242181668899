<template>
  <section>
    <b-overlay :show="show" rounded="sm">
      <QuestionSearch/>
      <b-card v-if="_questions2.length>0">
        <Pagination :pagination="_pagination" @pageClick="pageClick"/>
      </b-card>
      <b-card>
        <b-row>
          <b-col cols="1">
            Oluşturma Tarihi <br>
            Soru No
          </b-col>
          <b-col cols="1">
            Mağaza <br>
            Durum
          </b-col>
          <b-col cols="1">
            Ürün Resim
          </b-col>
          <b-col cols="1">
            Ürün Bilgileri
          </b-col>
        </b-row>
      </b-card>
      <QuestionItem
          v-for="question in _questions2"
          :key="question.id"
          :question="question"
      />
      <b-card v-if="_questions2.length>0">
        <Pagination :pagination="_pagination" @pageClick="pageClick"/>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import QuestionSearch from '@/views/question/QuestionSearch.vue'
import QuestionItem from "@/views/question/QuestionItem.vue";
import {mapGetters} from "vuex";
import Pagination from "@/components/Pagination.vue";

export default {
  name: 'QuestionHome',
  components: {
    Pagination,
    QuestionSearch, QuestionItem
  },
  data: () => ({
    show: false,
  }),
  computed: {
    ...mapGetters('question', ['_questions2', '_pagination', '_search'])
  },
  methods: {
    pageClick(page) {
      this.show = true
      this._search.page = page
      this.$store.dispatch('question/getAll2')
          .finally(() => {
            this.show = false
          })
    }
  },
  created() {
    this.show = true
    this.$store.dispatch("question/getAll2")
        .finally(() => {
          this.show = false
        })
    this.$store.dispatch('readyAnswer/getAll')
  }

}

</script>

<style scoped>
.card {
  margin: 5px;
}

.card-body {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
