<template>
  <b-card :style="questionStyle">

    <b-row>
      <b-col xs="12" sm="6" md="3" lg="1">
        <ViewDate name="" :date="question.creation_date"/>
        <br>
        {{ question.question_id }}
      </b-col>
      <b-col xs="12" sm="6" md="3" lg="1">
        {{ question.store.store_name }} <br>
        {{ getStatus.name }}
      </b-col>
      <b-col xs="12" sm="6" md="3" lg="1">
        <img height="50px" :src="question.image_url">
      </b-col>
      <b-col cols="2">
        {{ question.product_name }} <a :href="question.web_url" target="mp">L</a> <br>
      </b-col>
      <b-col cols="5">
        <div>{{ question.question }}</div>
        <div v-if="question.answer && question.answer.id">
          <hr>
          {{ question.answer.text }}
        </div>
        <br>

      </b-col>
      <b-col cols="1">
        <b-button-group size="sm">
          <b-button :disabled="!isWaitting" variant="warning" @click="showAnswer=!showAnswer">Cevapla</b-button>
          <b-button variant="danger" @click="showAnswer=!showAnswer">Bildir</b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <b-row v-for="product in products" :key="product.id">
      <OrderProductSupplierProduct :product="product" :productAccounting="{}"/>
    </b-row>

    <b-row v-if="showAnswer">
      <b-col cols="10">
        <div>
          <b-badge
              variant="success"
              v-for="readyAnswer in _readyAnswers"
              :key="readyAnswer.id"
              @click="setAnswerText(readyAnswer)"
          >
            {{ readyAnswer.title }}
          </b-badge>
        </div>
        <div>
          <b-form-textarea
              id="textarea"
              v-model="question.answer_text"
              placeholder="Cevap Yazınız..."
          ></b-form-textarea>
        </div>
      </b-col>
      <b-col cols="2">
        <b-btn-group size="sm">
          <b-button
              :disabled="!isSendAnswer"
              @click="sendAnswer"
              variant="success"
          >
            Gönder
          </b-button>
          <b-button
              variant="danger"
              @click="question.answer=''"
          >
            Temizle
          </b-button>
        </b-btn-group>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {mapGetters} from "vuex";
import ViewDate from "@/views/common/ViewDate.vue";
import OrderProductSupplierProduct from '@/views/order/list/OrderProductSupplierProduct'

export default {
  name: 'QuestionItem',
  components: {ViewDate, OrderProductSupplierProduct},
  props: {
    question: {type: Object, required: true},
  },
  data: () => ({
    showAnswer: false,
  }),
  computed: {
    ...mapGetters('question', ['_statuses']),
    ...mapGetters('readyAnswer', ['_readyAnswers']),
    products() {
      if (this.question.mp_product && this.question.mp_product.mp_product && this.question.mp_product.mp_product.products)
        return this.question.mp_product.mp_product.products;
      return []
    },
    isSendAnswer() {
      if (this.question.answer_text === null) return false
      return this.question.answer_text.length >= 10 && this.question.answer_text.length <= 2000
    },
    getStatus() {
      return this._statuses.find(item => {
        return item.key === this.question.status
      })
    },
    isWaitting() {
      return ['WAITING_FOR_ANSWER', 'WAITING_FOR_APPROVE'].includes(this.question.status)
    },
    questionStyle() {
      if (this.isWaitting) return 'background-color: rgb(255, 146, 146, 15%)'
    }
  },
  methods: {
    setAnswerText(readyAnswer) {
      this.question.answer_text = readyAnswer.content
    },
    sendAnswer() {
      this.$store.dispatch('question/answer', {
        id: this.question.id,
        answer_text: this.question.answer_text
      })
    }
  }
}
</script>